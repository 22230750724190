<template>
    <!-- 背景音 -->
    <!-- <audio id="audio--bg" :src="require('@/assets/audio/bg.wav')" preload autoplay loop></audio> -->
    <!-- 按鈕 start -->
    <audio id="audio--start" :src="require('@/assets/audio/1_start.mp3')" preload></audio>
    <!-- 洗球 -->
    <audio id="audio--shuffe" :src="require('@/assets/audio/2_shuffe.mp3')" preload></audio>
    <!-- 按鈕 twist -->
    <audio id="audio--twist" :src="require('@/assets/audio/3_twist.mp3')" preload></audio>
    <!-- 扭蛋滾動 -->
    <audio id="audio--roll" :src="require('@/assets/audio/4_roll.mp3')" preload></audio>
    <!-- 球打開 -->
    <audio id="audio--open" :src="require('@/assets/audio/5_open.mp3')" preload></audio>

    <div v-if="!isDown" class="lottery">
        <!-- loading -->
        <PagesLoading v-show="isPagesLoading"/>

        <div v-show="!isPagesLoading">
            <section id="sec1" class="sec">
                <div class="wrap">
                    <h1>
                        <img class="w-100" src="@/assets/images/lottery/img_title.gif" alt="">
                    </h1>
                    <a class="btn start" @click="L_start"></a>
                </div>
            </section>    
            <section id="sec2" class="sec">
                <div id="lottie_1"></div>
                <div id="lottie_2"></div>        
                <div class="wrap">
                    <div class="wrap-left">
                        <h1>
                            <img class="w-100" src="@/assets/images/lottery/img_title.gif" alt="">
                        </h1>
                        <div class="prize-wrap">
                            <div class="prize-img">
                                <img class="w-100" src="" alt="">
                            </div>
                            <div class="prize-info">
                                <span class="now">目前獎項</span>
                                <span class="title">----</span>
                                <span class="price">價值 <small>NT$</small><span>--</span></span>
                                <span class="num">抽出 <span>--</span> 名</span>
                            </div>
                            <img class="deco-tree" src="@/assets/images/lottery/deco.png" alt="">
                        </div>
                    </div>
                </div>
            </section>  
            <section id="lottie_3" class="sec">
            </section>  
            <section id="sec3" class="sec">
                <div class="light"></div>
                <div class="wrap">
                    <div class="winner-wrap">
                        <!-- <div v-if="winners.length==0" class="load-3">
                            <div class="line"></div>
                            <div class="line"></div>
                            <div class="line"></div>
                        </div> -->
                        <div v-if="winners.length==0" class="load-5">
                            <div class="ring-2">
                                <div class="ball-holder">
                                    <div class="ball"></div>
                                </div>
                            </div>
                        </div>
                        <div v-show="winners.length!==0" class="winner-info">
                            <div class="name"></div>
                            <div class="phone"></div>
                        </div>
                        <div v-show="winners.length!==0" class="prize">
                            <span>獲 得</span>
                            <img src="" alt="">
                            <span></span>
                        </div>
                    </div>
                    <img class="status" src="@/assets/images/lottery/end.png" alt="">
                    <a class="btn next" @click="L_next(SN)"></a>
                    <a class="btn seelist" @click="isDown=!isDown"></a>
                </div>
            </section>
        </div>
    </div>

    <div v-else class="winner-list row justify-content-center py-5">
        <!-- loading -->
        <PagesLoading v-show="isPagesLoading"/>

		<div v-if="!isPagesLoading" class="col-12 col-xl-10" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
            <div class="winner-list__contain row justify-content-center">
                <div class="col-xl-8 mb-3">
                    <img v-if="deviceMode != 'mobile'" class="winner-list__title d-block mx-auto mb-4" src="@/assets/images/winner/img_title.png" alt="全民票選抽大獎獲獎名單">
                    <img v-else class="winner-list__title d-block mx-auto mb-4 mb-md-0" src="@/assets/images/winner/img_title_s.png" alt="全民票選抽大獎獲獎名單">				
                </div>
                <div class="col-xl-10">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card d-flex flex-sm-row mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g1.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">iPhone 12(128GB)</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_1[0]!==undefined">
                                        <span class="info__name">{{winner_1[0].Name}}</span> /
                                        <span class="info__phone">{{winner_1[0].Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card d-flex flex-sm-row mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g2.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">Samsung Galaxy Watch Active2 44mm鋁製(藍牙)智慧手錶</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_2[0]!==undefined">
                                        <span class="info__name">{{winner_2[0].Name}}</span> /
                                        <span class="info__phone">{{winner_2[0].Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card mb-4">
                                <div class="row">
                                    <div class="col-lg-6 d-md-flex">
                                        <div class="winner-list__gift">
                                            <img src="@/assets/images/winner/img_g3.png" alt="">
                                        </div>
                                        <div class="winner-list__info d-flex">
                                            <span class="info__title text-center text-danger">將捷金鬱金香酒店<br>標準客房 平日住宿券</span>
                                            <small class="font-weight-bold">( 共<span class="info__num">10</span>名 )</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-wrap text-center align-self-center justify-content-center justify-content-md-start">
                                        <template v-if="winner_3[0]!==undefined">  
                                            <span v-for="i in winner_3.slice(0,10)" :key="i" class="d-inline-block" :class="{'w-50': deviceMode!=='mobile'}">
                                                <span class="info__name">{{i.Name}}</span> /
                                                <span class="info__phone">{{i.Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}}</span>
                                            </span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card d-flex flex-sm-row flex-lg-column mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g4.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">SONY 可攜式藍牙喇叭 <br>SRS-XB23</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_4[0]!==undefined">
                                        <span class="info__name">{{winner_4[0].Name}}</span> /
                                        <span class="info__phone">{{winner_4[0].Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card d-flex flex-sm-row flex-lg-column mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g5.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">Audio-Technica 鐵三角<br>真無線耳機 ATH-SQ1TW</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_5[0]!==undefined">
                                        <span class="info__name">{{winner_5[0].Name}}</span> /
                                        <span class="info__phone">{{winner_5[0].Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card d-flex flex-sm-row flex-lg-column mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g6.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">日本麗克特récolte<br>格子三明治機</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_6[0]!==undefined">
                                        <span class="info__name">{{winner_6[0].Name}}</span> /
                                        <span class="info__phone">{{winner_6[0].Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card mb-4">
                                <div class="row">
                                    <div class="col-lg-6 d-md-flex">
                                        <div class="winner-list__gift">
                                            <img src="@/assets/images/winner/img_g7.png" alt="">
                                        </div>
                                        <div class="winner-list__info d-flex">
                                            <span class="info__title text-center text-danger">滬尾藝文休閒園區<br>商品券</span>
                                            <small class="font-weight-bold">( 共<span class="info__num">15</span>名 )</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-wrap text-center align-self-center justify-content-center justify-content-md-start">
                                        <template v-if="winner_7[0]!==undefined">  
                                            <span v-for="i in winner_7.slice(0,15)" :key="i" class="d-inline-block" :class="{'w-50': deviceMode!=='mobile'}">
                                                <span class="info__name">{{i.Name}}</span> /
                                                <span class="info__phone">{{i.Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}}</span>
                                            </span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import PagesLoading from '@/components/PagesLoading.vue';
import $ from 'jquery';
import lottie from 'lottie-web'
import data1 from '@/assets/lottie/lottery_data1.json'
import data2 from '@/assets/lottie/lottery_data2.json'
import data3 from '@/assets/lottie/lottery_data3.json'

export default {
    components: {
        PagesLoading,
    },
	data() {
		return {
            gachaArr: [data1,data2,data3],
			gachaBox: ['lottie_1','lottie_2','lottie_3'],
            allPrize:[
                {
                    order: 1,
                    title: 'Apple<br>iPhone 12',
                    title_detail: 'Apple<br>iPhone 12 (128GB)',
                    price: '28,500',
                    num: '1',
                    img_L: require('@/assets/images/lottery/img_p1.png'),
                    img_S: require('@/assets/images/lottery/img_p1_s.png')
                },
                {
                    order: 2,
                    title: 'Samsung Galaxy<br>Watch Active2',
                    title_detail: 'Samsung Galaxy<br>Watch Active2 44mm<br>鋁製(藍牙)智慧手錶',
                    price: '8,990',
                    num: '1',
                    img_L: require('@/assets/images/lottery/img_p2.png'),
                    img_S: require('@/assets/images/lottery/img_p2_s.png')
                },
                {
                    order: 3,
                    title: '將捷金鬱金香酒店<br>標準客房平日住宿券',
                    title_detail: '將捷金鬱金香酒店<br>標準客房<br>平日住宿券',
                    price: '7,920',
                    num: '10',
                    img_L: require('@/assets/images/lottery/img_p3.png'),
                    img_S: require('@/assets/images/lottery/img_p3_s.png')
                },
                {
                    order: 4,
                    title: 'SONY<br>可攜式藍牙喇叭',
                    title_detail: 'SONY 可攜式藍牙喇叭<br>SRS-XB23',
                    price: '3,490',
                    num: '1',
                    img_L: require('@/assets/images/lottery/img_p4.png'),
                    img_S: require('@/assets/images/lottery/img_p4_s.png')
                },
                {
                    order: 5,
                    title: 'Audio-Technica<br>鐵三角 真無線耳機',
                    title_detail: 'Audio-Technica<br>鐵三角 真無線耳機<br>ATH-SQ1TW',
                    price: '3,000',
                    num: '1',
                    img_L: require('@/assets/images/lottery/img_p5.png'),
                    img_S: require('@/assets/images/lottery/img_p5_s.png')
                },
                {
                    order: 6,
                    title: '日本麗克特 récolte<br>格子三明治機',
                    title_detail: '日本麗克特 récolte<br>格子三明治機',
                    price: '1,680',
                    num: '1',
                    img_L: require('@/assets/images/lottery/img_p6.png'),
                    img_S: require('@/assets/images/lottery/img_p6_s.png')
                },
                {
                    order: 7,
                    title: '滬尾藝文休閒園區<br>商品券',
                    title_detail: '滬尾藝文休閒園區<br>商品券<br>NT$1,000',
                    price: '1,000',
                    num: '15',
                    img_L: require('@/assets/images/lottery/img_p7.png'),
                    img_S: require('@/assets/images/lottery/img_p7_s.png')
                },
            ],
            winners:[],
            allWinners:[],
            winner_1:[],
            winner_2:[],
            winner_3:[],
            winner_4:[],
            winner_5:[],
            winner_:[],
            winner_7:[],
            SN: null,
            isPagesLoading: true,
            isStart: true,
            isDown: false
		}
	},
    created(){
        this.NowSN();
    },    
	mounted() {
        const t=this;
        const audioStart = document.getElementById("audio--start");
        const audioShuffe = document.getElementById("audio--shuffe");
        const audioTwist = document.getElementById("audio--twist");
        const audioRoll = document.getElementById("audio--roll");
        const audioOpen = document.getElementById("audio--open");
        // audioStart.currentTime = 0;
        
        /*
        // 重新抽獎
        $(document).keypress(function( e ) { 
            if (e.which == 99 || e.which == 67) {// C鍵觸發(大小寫ok)
                t.axios({
                    method: 'get',
                    url: 'https://api.2021tamsui.tw/Eve/T21Ing/7',
                })
                .then(response => {
                    if(response.status===200){
                        alert('重置完成');
                        window.location.reload();
                    }
                })
            }
        })

        // 更新名單
        $(document).keypress(function( e ) { 
            if (e.which == 113 || e.which == 81) {// Q鍵觸發(大小寫ok)
                $('body').append(`<div class="alert alert-danger position-fixed rounded-0 w-100" style="top: 0; z-index: 10;">清除中...</div>`)
                t.axios({
                    method: 'get',
                    url: 'https://api.2021tamsui.tw/Eve/T21ReAll',
                })
                .then(response => {
                    if(response.status===200){
                        alert('清除完成');
                        $('.alert').remove();
                        window.location.reload();
                    }
                })
            }
        })*/

        this.L1 = lottie.loadAnimation({
            container: document.getElementById('lottie_1'),
            animType: 'svg',
            autoplay: false,
            loop: true,
            animationData: data1
        });
        this.L2 = lottie.loadAnimation({
            container: document.getElementById('lottie_2'),
            animType: 'svg',
            autoplay: false,
            loop: false,
            animationData: data2
        });
        this.L3 = lottie.loadAnimation({
            container: document.getElementById('lottie_3'),
            animType: 'svg',
            autoplay: false,
            loop: false,
            animationData: data3
        });

        this.L1.play();
        this.L1.addEventListener('DOMLoaded', () => {
            $('#lottie_1 .start').on('mouseover',function(){
                $(this).css({cursor: 'url(https://xyzofficialweb.blob.core.windows.net/web-v3/3DPKnowl_BannerImg_Test_cursor_pointer.png), auto'});
            }).on('click',function(){
                if(t.isStart){
                    t.isStart=false;
                    t.L2.play();
                    audioStart.play();
                    $('#lottie_1').hide();
                    $('#lottie_2').show();
                    t.winners=[];
                    t.winners = t.allWinners.filter(function(item){
                        return item.Awards == t.SN;
                    });
                    let winnerNum = t.allPrize[(t.SN-1)].num;
                    t.Gacha(t.allPrize[(t.SN-1)], t.winners.slice(0,winnerNum));
                }
            });
        });

        this.L2.addEventListener('enterFrame', (e) => {            
            if(Math.round(e.currentTime).toFixed() > 10 && Math.round(e.currentTime).toFixed() < 25){
                audioShuffe.play();
            }
        });
        this.L2.addEventListener('DOMLoaded', () => {
            $('#lottie_2 .turn').on('mouseover',function(){
                $(this).css({cursor: 'url(https://xyzofficialweb.blob.core.windows.net/web-v3/3DPKnowl_BannerImg_Test_cursor_pointer.png), auto'});
            }).on('click',function(){
                $('#sec2, #lottie_2').hide();
                $('#lottie_3').show();
                t.L3.play(); 
            });
        });

        this.L3.addEventListener('enterFrame', (e) => {
            if(Math.round(e.currentTime).toFixed() > 60 && Math.round(e.currentTime).toFixed() < 75){
                audioTwist.play();
            }
            if(Math.round(e.currentTime).toFixed() > 97 && Math.round(e.currentTime).toFixed() < 115){
                audioRoll.play();
            }
            if(Math.round(e.currentTime).toFixed() > 158 && Math.round(e.currentTime).toFixed() < 185){
                audioOpen.play();
            }
            if(Math.round(e.currentTime).toFixed() < 250 && Math.round(e.currentTime).toFixed() > 235){
                $('#sec3').show();        
                if(this.SN == 1){
                    $("#sec3").addClass("end");
                }
            }
            if(Math.round(e.currentTime).toFixed() < 295 && Math.round(e.currentTime).toFixed() > 285){
                $('#lottie_3').hide();
            }
        });
        this.L3.addEventListener('complete', () => {
            t.L2.stop();
            t.L3.stop();
            t.isStart = true;
        });
	},
	methods: {
        L_init(sn){
            if(sn!==null){
                this.axios({
                    method: 'get',
                    url: 'https://api.2021tamsui.tw/Eve/T21Get',
                })
                .then(response => {
                    this.allWinners = response.data;
                    this.isPagesLoading = false;

                    this.winner_1 = this.allWinners.filter(item => item.Awards == '1');
                    this.winner_2 = this.allWinners.filter(item => item.Awards == '2');
                    this.winner_3 = this.allWinners.filter(item => item.Awards == '3');
                    this.winner_4 = this.allWinners.filter(item => item.Awards == '4');
                    this.winner_5 = this.allWinners.filter(item => item.Awards == '5');
                    this.winner_6 = this.allWinners.filter(item => item.Awards == '6');
                    this.winner_7 = this.allWinners.filter(item => item.Awards == '7');
                })
                if(sn == 7){
                    $('#sec2, #sec3, #lottie_1, #lottie_2, #lottie_3').hide();
                } else if(sn == 1) {
                    this.isDown=true;
                } else {
                    $('#sec1, #sec3, #lottie_2, #lottie_3').hide();
                }
                this.NowPrize(this.allPrize[(sn-1)]);
            }
        },

        L_start(){
            $('#sec1').hide();
            $('#sec2, #lottie_1').show(); 
        },

        L_next(sn){
            this.Clear();
            this.SN = sn - 1;

            $('#sec3, #lottie_3').hide();
            $('#sec2, #lottie_1').show();
            this.NowPrize(this.allPrize[(this.SN-1)]);            
            this.axios({
                method: 'get',
                url: 'https://api.2021tamsui.tw/Eve/T21Ing/'+this.SN,
            })
        },        

        // 目前要抽第幾獎
        NowSN(){
            this.axios({
                method: 'get',
                url: 'https://api.2021tamsui.tw/Eve/T21Ing',
            })
            .then(response => {
                this.SN=response.data;
                this.L_init(this.SN);
            })
        },

        // 目前要抽獎項內容
        NowPrize(arr) {
            $('.prize-img img').attr("src",arr.img_L);
            $('.prize-wrap .title').html(arr.title);
            $('.prize-wrap .price span').text(arr.price);
            $('.prize-wrap .num span').text(arr.num);
        },

        // 中獎者列表
        Gacha(arr, winners) {
            let winnerList = '';            
            if(arr.num > 1){
                winners.forEach(function(item){
                    winnerList += `<span>${item.Name}/${item.Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}</span>`
                });                
            } else {
                winnerList += `<div class="name">${winners[0].Name}</div><div class="phone">${winners[0].Phone.replace(/(\d{4})\d*(\d{3})/, '$1***$2')}</div>`
            }
            $('.winner-info').html(winnerList);
            $('.prize img').attr("src",arr.img_S);
            $('.prize span:last-child').html(arr.title_detail);
        },

        // 清空
        Clear(){
            $('.prize-img img').attr("src",'');
            $('.prize-wrap .title').html('');
            $('.prize-wrap .price span').text('');
            $('.prize-wrap .num span').text('');
            $('.prize img').attr("src",'');
            $('.prize span:last-child').html('');
        }
	},
}
</script>